import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import PrivateRoute from '../../components1/PrivateRoute';
import UserDataWrapper from './UserDataWrapper';

import { Text as TextBeforeStyle, Title } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';
import FlexDiv from '../../components1/FlexDiv';
import Section from '../../components1/Section';

import CreditCardFormToWrap from '../forms/stripe/CreditCardForm';
import CreditCardList from '../payment/CardsList';
import StripeHOC from '../payment/StripeHOC';
import AddNewCard from '../payment/AddNewCard';
import UserInformationsModalForm from '../modals/UserInformations';
import { AddCardButton, CancelAddCardButton } from './components/buttons';

import { addCardToUser, removeCardToUser, setDefaultCardToUser } from '../../services';

const Text = styled(TextBeforeStyle)`
    padding: 5px 0;
`;

const dateToString = ({day, month, year}) => `${day}/${month}/${year}`;

const CreditCardForm = StripeHOC(CreditCardFormToWrap);

const UserInformations = ({user, authUserData}) => {

    const [showCardInput, toggleShowCardInput] = useState(false);
    const refModal = useRef(null);
    const formSubmitRef = useRef(null);

    if (isEmpty(user)) {
        return null;
    }

    const {
        gender,
        userInformations: {
            lastName,
            firstName,
            birthDate,
            phone,
        },
        billingAddress: {
            address,
            postalCode,
            city,
        },
        email,
        informations: {
            familyNumber,
            foodPreference,
        },
        paymentMethod: cards,
        defaultPaymentMethodId: userDefaultCardId,
        customerId: stripeCustomerId,
    } = user;

    return (
        <Section>
            <FlexDiv style={{flexDirection: 'column'}}>
                <Title
                    style={{
                        textAlign: 'center',
                        fontSize: '1.625rem',
                        padding: 10,
                    }}>
                    Mes coordonnées et informations personnelles
                </Title>
                <FlexDiv
                    css={`
                        @media only screen and (max-width: 599px) {
                            flex-direction: column;
                        }
                    `}
                >
                    <FlexDiv style={{flexDirection: 'column'}}>
                        <Text style={{fontWeight: 600}}>
                            {`Coordonnées :`}
                        </Text>
                        <Text
                            style={{textTransform: 'capitalize'}}
                        >
                            {
                                `${gender} ${lastName} ${firstName}`
                            }
                        </Text>
                        <Text>
                            {
                                `Né${gender === 'mme' ? 'e' : ''} le ${dateToString(birthDate)}`
                            }
                        </Text>
                        <Text>
                            {`Adresse: ${address} ${postalCode} ${city}`}
                        </Text>
                        <Text>
                            {`Téléphone: ${phone}`}
                        </Text>
                        <Text>
                            {`Adresse email: ${email}`}
                        </Text>
                        {
                            /*
                                <Text>
                                    {`Nombre de personne dans votre foyer: ${familyNumber}`}
                                </Text>
                                <Text>
                                    {`Préférences alimentaires: ${foodPreference}`}
                                </Text>
                            */
                        }
                        <FlexDiv
                            style={{
                                flex:0,
                                paddingTop: 20,
                                justifyContent: 'center'
                            }}
                        >
                            <CabadiciButton
                                onClick={() => refModal.current.openModal()}
                                color="rougeFort"
                            >Modifier</CabadiciButton>
                        </FlexDiv>
                    </FlexDiv>
                    <FlexDiv
                        style={{flexDirection: 'column'}}
                        css={`
                            @media only screen and (max-width: 599px) {
                                padding-top: 30px;
                            }
                        `}
                    >
                        <Text style={{fontWeight: 600}}>
                            {`Moyen(s) de paiement :`}
                        </Text>
                        {
                            typeof cards !== 'undefined' &&
                                <CreditCardList user={user} cards={Array.isArray(cards) ? cards : [cards]} userDefaultCardId={userDefaultCardId} />
                        }
                        {
                            !showCardInput ?
                                (
                                    stripeCustomerId && <AddNewCard style={{alignItems: 'flex-start', alignSelf: 'flex-start', paddingTop: 20}} onClick={() => toggleShowCardInput(!showCardInput)}/>
                                )
                                :
                                (
                                    <FlexDiv style={{flexDirection: 'column', paddingTop: 20}}>
                                        <Text>
                                            {`Nouvelle carte:`}
                                        </Text>
                                        <FlexDiv style={{justifyContent: 'center'}}>
                                            <CreditCardForm
                                                ref={formSubmitRef}
                                                style={{
                                                    flex:1,
                                                    maxWidth: '400px'
                                                }}
                                                user={user}
                                                handlePaymentStatusChange={()=>{}}
                                                paymentHandler={({ card }) => {
                                                    addCardToUser(card);
                                                    toggleShowCardInput(!showCardInput);
                                                    /*
                                                    updateUserProfil({
                                                        paymentMethod: [{...card, default: true}],
                                                    });
                                                    */
                                                }}
                                            />
                                            <AddCardButton
                                                onClick={() => {
                                                    console.log(formSubmitRef);
                                                    formSubmitRef.current.click();
                                                }}
                                            />
                                            <CancelAddCardButton onClick={() => toggleShowCardInput(!showCardInput)} />
                                        </FlexDiv>
                                    </FlexDiv>
                                )
                        }
                    </FlexDiv>
                </FlexDiv>
            </FlexDiv>
            <UserInformationsModalForm ref={refModal}/>
        </Section>
    )
};

UserInformations.propTypes = {};

export default PrivateRoute(
    // connect(mapStateToProps, mapDispatchToProps)(UserInformations);
    UserDataWrapper(UserInformations));
