import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardIcon = ({ brand }) => {
    switch (brand) {
    case 'MasterCard':
        return <FontAwesomeIcon icon={['fab', 'cc-mastercard']} size="2x" />
    case 'Visa':
        return <FontAwesomeIcon icon={['fab', 'cc-visa']} size="2x" />
    default:
        return <FontAwesomeIcon icon={['far', 'credit-card']} size="2x" />
    }
};

CardIcon.propTypes = {
    brand: PropTypes.string.isRequired,
};

export default CardIcon;
