import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, {withTheme} from 'styled-components';

import { Text as TextBeforeStyle, Title } from '../../components1/Texts';
import { Button } from '../../components1/buttons';
import FlexDiv from '../../components1/FlexDiv';

const Text = styled(TextBeforeStyle)`
    padding: 0 1.5rem;
`;

const AddCardButton = withTheme(({theme, ...props}) => {
    return (
        <Button {...props} transparent>
            <FontAwesomeIcon icon="plus" color={theme.color.vertFort} size='2x'/>
        </Button>
    );
});

export default ({style, ...props}) => (
    <FlexDiv style={{...style}}>
        <FlexDiv style={{ alignItems: 'center'}}>
            <AddCardButton {...props}/>
            <Text>
                Ajouter un nouveau moyen de paiement
            </Text>
        </FlexDiv>
    </FlexDiv>
)
