import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FlexDiv from '../../components1/FlexDiv';
import Card from './components/Card';

import { ModalsRefsContext } from '../user/UserDataWrapper';

import {
    setDefaultCardToUser, updateStripeDefaultCard,
    removeCardToUser, deleteCard
} from '../../services';

class CardsList extends PureComponent {
    static propTypes = {
        cards: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            brand: PropTypes.string.isRequired,
            cardLast4digit: PropTypes.string.isRequired,
            exp_month: PropTypes.number.isRequired,
            exp_year: PropTypes.number.isRequired
        })),
        userDefaultCardId: PropTypes.string.isRequired,
        user: PropTypes.shape({
            customerId: PropTypes.string.isRequired,
        }),
    };

    static contextType = ModalsRefsContext;

    updateDefaultCard = (event) => {
        const { user: { customerId: stripeCustomerId } } = this.props
        const cardId = event.currentTarget.value;
        updateStripeDefaultCard({
            stripeCustomerId,
            cardId
        })
            .then(() => {
                return setDefaultCardToUser(cardId);
            })
    };

    deleteCard = (cardId) => {
        const { user: { customerId: stripeCustomerId }, userDefaultCardId, cards } = this.props;
        const getRefs = this.context;
        const { alertModalRef } = getRefs();
        const suppressCard = () => {
            return deleteCard({
                stripeCustomerId,
                cardId
            }).then(() => {
                return removeCardToUser(cards.find(card=> card.id === cardId));
            })
        };

        if (cards.length === 1) {
            return alertModalRef.current.setData({
                title: 'Attention !',
                message: ['Vous allez supprimer votre unique moyen de paiement', 'Veuillez ajouter un nouveau moyen de paiement avant de supprimer celui-ci.'],
            });
        }

        if (cardId === userDefaultCardId) {
            return alertModalRef.current.setData({
                title: 'Attention !',
                message: ['Vous essayez de supprimer votre moyen de paiement par défaut !', 'Veuillez en choisir un autre avant de supprimer celui-ci.'],
            });
        }
        
        return alertModalRef.current.setData({
            title: 'Suppression',
            message: 'Vous allez supprimer un moyen de paiement',
            callback: suppressCard
        });
    }

    render() {
        const { cards, userDefaultCardId } = this.props;
        return (
            <FlexDiv style={{flexDirection: 'column'}}>
                {
                    cards.map((cardData, i) => (
                        <Card
                            card={cardData}
                            key={i.toString()}
                            defaultCardId={userDefaultCardId}
                            handleChange={this.updateDefaultCard}
                            handleDelete={this.deleteCard}
                        />
                    ))
                }
            </FlexDiv>
        )
    }
}

export default CardsList;
