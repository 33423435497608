import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modalize from '../../components/Modalizer_';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import FlexDiv from '../../components1/FlexDiv';
import Separator from '../../components1/Separator';
import UserInformationsForm from '../forms/UserInformations';
import { updatePrivateDeliveryAddress, updateUserProfil } from '../../actions';

const mapStateToProps = ({user, authUserData}) => ({user, authUserData});

const mapDispatchToProps = dispatch => {
    return {
        updatePrivateDeliveryAddress:  value => dispatch(updatePrivateDeliveryAddress(value)),
        updateUserProfil:  value => dispatch(updateUserProfil(value)),
    };
};

const UserInformations = ({ closeModal, updatePrivateDeliveryAddress, updateUserProfil, authUserData, user: {
    gender,
    userInformations: {
        birthDate,
        lastName,
        firstName,
        phone,
    },
    billingAddress: {
        address,
        postalCode,
        city,
    },
    informations: {
        familyNumber,
        foodPreference,
    }} }) => {
    const formRef = useRef(null);
    
    return (
        <>
            <UserInformationsForm
                initialValues={{
                    gender,
                    userInformations: {
                        birthDate,
                        lastName,
                        firstName,
                        phone,
                    },
                    billingAddress: {
                        address,
                        postalCode,
                        city,
                    },
                    informations: {
                        familyNumber: familyNumber.toString(),
                        foodPreference: foodPreference.toString(),
                    }
                }}
                ref={formRef}
                onSubmit={(data) => {
                    updateUserProfil(data);
                    closeModal();
                }}
                authUserData={authUserData}
            />
            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="jauneFort"
                        onClick={closeModal}

                    />
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 3}}
                >
                    <CabadiciButton
                        color="rougeFort"
                        onClick={(...args) => {
                            formRef.current.handleSubmit(...args);
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.625rem !important;
                            }
                        `}
                    >
                        Modifier
                    </CabadiciButton>
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>

        </>
    );
};

UserInformations.propTypes = {
    closeModal: PropTypes.func.isRequired,
    updatePrivateDeliveryAddress: PropTypes.func.isRequired,
};

export default Modalize(connect(mapStateToProps, mapDispatchToProps)(UserInformations));
