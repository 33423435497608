import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text as TextBeforeStyle } from '../../../components1/Texts';
import FlexDiv from '../../../components1/FlexDiv';
import CardIcon from './CardIcon';

const Text = styled(TextBeforeStyle)`
    padding: 5px 0;
`;

// https://github.com/styled-components/styled-components/issues/1186
const HandlerInputDiv = styled(FlexDiv)`
    input:checked ~ & {
        border: 1px solid blue !important;
    }
`

const CardDetail = ({ card:{ brand, cardLast4digit, exp_month: expMonth, exp_year: expYear }}) => {
    return (
        <HandlerInputDiv
            style={{
                alignItems: 'center',
                border: '1px solid #eee',
                padding: '0.75rem 1rem',
                fontSize: '1rem',
                margin: '0.5rem',
                borderRadius: '3px',
                outline: 0,
                width: '100%',
                maxWidth: '400px'
            }}
        >
            <FlexDiv
                style={{flex: 0}}
            >
                <CardIcon brand={brand} />
            </FlexDiv>
            <FlexDiv
                style={{flex: 2}}
                css={`
                    @media only screen and (max-width: 599px) {
                        flex-direction: column;
                        padding-left: 30px;
                        align-items: flex-start;
                    }
                `}
            >
                <FlexDiv style={{flex: 2, justifyContent: 'center'}}>
                    <Text
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 0.65rem;
                            }
                        `}
                    >
                        {`XXXX XXXX XXXX ${cardLast4digit}`}
                    </Text>
                </FlexDiv>
                <FlexDiv style={{justifyContent: 'center'}}>
                    <Text
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 0.65rem;
                            }
                        `}
                    >
                        {`${expMonth.toString().padStart(2, '0')} / ${expYear}`}
                    </Text>
                </FlexDiv>
            </FlexDiv>
        </HandlerInputDiv>
    );
};

CardDetail.propTypes = {
    card: PropTypes.shape({
        brand: PropTypes.string.isRequired,
        cardLast4digit: PropTypes.string.isRequired,
        exp_month: PropTypes.number.isRequired,
        exp_year: PropTypes.number.isRequired
    }).isRequired
};

export default CardDetail;
