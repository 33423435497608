import React from 'react';

import { Layout } from '../../components1/layout';
import UserInformations from '../../containers/user/MyInformations';
import PrivateRoute from '../../components1/PrivateRoute';

const InformationsPage = () => (
    <Layout isUserPage>
        {
            () => (
                <UserInformations />
            )
        }
    </Layout>
);

export default InformationsPage;
