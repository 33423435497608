import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../components1/buttons';

export const RemoveButton = withTheme(({cardId, onClick, theme, ...props}) => {
    return (
        <Button {...props} transparent onClick={()=>{onClick(cardId)}}>
            <FontAwesomeIcon icon="trash-alt" color={theme.color.rougeFort} size='2x'/>
        </Button>
    );
});

RemoveButton.propTypes = {
    cardId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    theme: PropTypes.shape().isRequired,
};
