import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { GATSBY_STRIPE_API_KEY } from '../../Constants';

export default (Component) => React.forwardRef(({ user, handlePaymentStatusChange, paymentHandler, style }, ref) => (
    <StripeProvider apiKey={GATSBY_STRIPE_API_KEY} >
        <Elements>
            <Component
                style={style}
                userData={user}
                handlePaymentStatusChange={handlePaymentStatusChange}
                paymentHandler={paymentHandler}
                innerRef={ref}
            />
        </Elements>
    </StripeProvider>
));
