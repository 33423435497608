import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlexDiv from '../../../components1/FlexDiv';
import CardDetail from './CardDetail';
import { RemoveButton } from './buttons';

const Input = styled.input`
    @media only screen and (min-width: 599px) {
        font-size: 14px;
        height: 45px;
    }
`;

const Card = ({ card, defaultCardId, handleChange, handleDelete }) => {
    return (
        <FlexDiv style={{ alignItems: 'center'}}>
            <Input
                type="radio"
                name="creditcard"
                value={card.id}
                checked={card.id === defaultCardId}
                onChange={handleChange}
                style={{padding: '0.5rem'}}
                css={`
                    @media only screen and (min-width: 600px) {
                        flex-basis: 50;
                    }
                `}
            />
            <CardDetail
                card={card}
                style={{
                    paddingLeft: 30
                }}
            />
            <RemoveButton cardId={card.id} onClick={handleDelete}/>
        </FlexDiv>
    );
};

Card.propTypes = {
    card: PropTypes.shape({
        id: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        cardLast4digit: PropTypes.string.isRequired,
        exp_month: PropTypes.number.isRequired,
        exp_year: PropTypes.number.isRequired
    }).isRequired,
    defaultCardId: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default Card;
