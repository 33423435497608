import React, { useState, useEffect } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import './CheckoutForm.css';

import { CabadiciButton } from '../../../components1/buttons';
import { createCard } from '../../../services';

const CreditCardForm = ({ stripe, innerRef, handlePaymentStatusChange, paymentHandler, style, userData }) => {
    const [status, setStatus] = useState('default');
    useEffect(() => handlePaymentStatusChange(status), [status])

    const { customerId: stripeCustomerId } = userData;

    const submit = async e => {
        e.preventDefault();
        setStatus('submitting');

        return stripe.createToken()
            .then(result => {
                return createCard({
                    stripeCustomerId,
                    token: result.token.id
                })
            })
            .then(({ data }) => {
                return paymentHandler({
                    customerId: data.customer,
                    card: {
                        id: data.id,
                        brand: data.brand,
                        exp_month: data.exp_month,
                        exp_year: data.exp_year,
                        cardLast4digit: data.last4
                    }
                });
            })
            .then(() => {
                setStatus('complete');
            })
            .catch(err=>{console.log(err);})
    };

    if (status === 'complete') {
        return <div className="CheckoutForm-complete">Carte ajoutée</div>;
    }

    return (
        <div style={style}>
            <form className="CheckoutForm" onSubmit={submit} >
                <CardElement hidePostalCode />
                <CabadiciButton
                    ref={innerRef}
                    css="display: none;"
                    color="vertFort"
                    classNameNo="CheckoutForm-button"
                    type="submit"
                    disabled={status === 'submitting'}>
                    {status === 'submitting' ? 'En cours' : 'Payer'}
                </CabadiciButton>
                {status === 'error' && (
                    <div className="CheckoutForm-error">Une erreur est apparue.</div>
                )}
            </form>
        </div>
    );
};

export default injectStripe(CreditCardForm);
